import type { SearchProductsQuery } from '@ecomm/commercetools/queries/Warranties.generated';
import type { Product, ProductVariant } from '@ecomm/commercetools/types.generated';

const CHECKOUT_UPSELL_REFS = 'checkout-upsell-refs';

const mapSearchProductsResponseToUpsellVariants = (
  queryResponse: SearchProductsQuery,
  productKey: string,
): ProductVariant[] => {
  const productResponse = queryResponse.productProjectionSearch.results.find(
    result => result.key === productKey,
  );

  const upsellProducts = productResponse?.masterVariant.attributesRaw.find(
    attribute => attribute.name === CHECKOUT_UPSELL_REFS,
  )?.referencedResourceSet;

  return (upsellProducts ?? [])
    .filter((item): item is Product => item.__typename === 'Product')
    .flatMap(product => {
      const productData = product?.masterData?.current;
      return [productData?.masterVariant]
        .concat(productData?.variants ?? [])
        .filter((item): item is ProductVariant => !!item);
    });
};

export default mapSearchProductsResponseToUpsellVariants;
