import { brand, grey, slate } from '@pelotoncycle/design-system';
import styled from 'styled-components';
import { media } from '@peloton/styles';
import { dividerGray } from '@ecomm/colors';
import { l3Dark } from '@ecomm/links/links';
import { b4, b5, b6, reg } from '@ecomm/typography';

export const Row = styled.div`
  margin-top: 30px;
`;

export const Item = styled.div`
  display: flex;
  ${b4}
  ${reg}
  color: ${brand.darkest};
`;

export const Quantity = styled.span`
  margin-right: 10px;
`;

export const Title = styled.span`
  display: flex;
  flex-grow: 1;
`;

export const Price = styled.span`
  text-align: right;
  flex-basis: ${(props: { amount: number }) => (props.amount > 0 ? '100px' : '50px')};
  margin-left: 10px;
`;

export const Details = styled.ul`
  ${b5}
  color: ${slate[50]};
  margin-left: 20px;
  margin-top: 6px;
  text-decoration: none;
`;

export const Detail = styled.li`
  margin-top: 3px;
`;

export const RemovableDetail = styled.ul`
  ${b6}
  color: ${grey[70]};
  margin: 4px 0;
  text-decoration: none;
`;

export const RemovableRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;

  :not(:first-child) {
    padding-top: 20px;
    border-top: 1px solid ${dividerGray};
  }
`;

export const Info = styled.div`
  position: relative;
`;

export const Actions = styled.div`
  text-align: right;
  margin-left: 5px;
`;

export const RemovableQuantity = styled.div`
  ${b5}
  color: ${brand.darkest};
  margin-bottom: 5px;
`;

export const Section = styled.div`
  ${b4}
  ${reg}
  max-width: 256px;

  & + & {
    margin-top: 5px;
  }
`;

export const TitleSection = styled(Section)`
  display: flex;
  position: relative;

  ${media.tablet`
    max-width: 300px;
    width: 300px;
  `}
`;

export const RemoveLink = styled.button`
  ${l3Dark({
    property: 'text-decoration',
    from: 'underline',
    to: 'underline',
  })}
  display: block;
  ${b5}
`;

export const MarginDivider = styled.hr`
  border: none;
  background-color: ${dividerGray};
  height: 1px;
  margin-top: 20px;
  margin-bottom: 20px;
`;
