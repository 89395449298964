import { black, brand, Icon } from '@pelotoncycle/design-system';
import { rgba } from 'polished';
import React from 'react';
import styled from 'styled-components';
import type { Props as TooltipProps } from '@ecomm/tooltip';
import Tooltip from '@ecomm/tooltip';

type Props = Pick<TooltipProps, 'renderTarget'> & {
  tooltipId?: string;
  children?: JSX.Element | React.ReactNode;
  className?: string;
};

const InfoTooltip: React.FC<React.PropsWithChildren<Props>> = ({
  renderTarget,
  children,
  className,
  tooltipId = 'tooltip',
}) => (
  <Tooltip
    tooltipContentId={tooltipId}
    renderTarget={renderTarget}
    renderTooltip={({ ref, style, placement }) => (
      <Container
        id={tooltipId}
        role="tooltip"
        className={className}
        ref={ref}
        style={style}
        data-placement={placement}
        aria-live="polite"
      >
        {children}
      </Container>
    )}
  />
);

export default InfoTooltip;

const Container = styled.div`
  background-color: white;
  border-radius: 3px;
  box-shadow: 0 3px 5px 0 ${rgba(black, 0.1)};
  color: ${brand.darkest};
  padding: 20px;
  text-align: center;
  min-width: 300px;
  width: min-content;
`;

export const StyledInfoIcon = styled(Icon)`
  cursor: pointer;
  margin: 0 8px;
  position: relative;
  top: 3px;
`;
