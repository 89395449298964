import type { ClientError } from '@peloton/api';
import { isHttpError } from '@peloton/api/ClientError';
import { AddToCartErrorCode } from '@peloton/internationalize';

const apiErrorMap = {
  8003: AddToCartErrorCode.TooManyWarranties,
  7101: AddToCartErrorCode.TooManyLeases,
  7102: AddToCartErrorCode.CartIsRegularEquipmentOnly,
  7103: AddToCartErrorCode.CartIsLeasedEquipmentOnly,
};

export const getErrorCode = (error: number): AddToCartErrorCode =>
  apiErrorMap[error] || AddToCartErrorCode.Default;

export const toErrorCode = (error: ClientError): AddToCartErrorCode => {
  const apiErrorCode = isHttpError(error) ? error.responseBody.errorCode : undefined;

  return apiErrorMap[apiErrorCode] || AddToCartErrorCode.Default;
};
