// THIS IS AN AUTOGENERATED FILE (@ecomm/graphql/codegen.yml). DO NOT EDIT THIS FILE DIRECTLY.
/* eslint-disable */
import * as Types from '../../graphql/types.generated';

import { DocumentNode } from 'graphql';
import * as ApolloReactCommon from '@ecomm/graphql/hooks';
import * as ApolloReactHooks from '@ecomm/graphql/hooks';
export type VariantsBySelectionsQueryVariables = Types.Exact<{
  productSelections: Array<Types.ProductSelection>;
  locale: Types.Locale;
  currency: Types.Currency;
  slug: Types.Scalars['Slug'];
}>;

export type VariantsBySelectionsQuery = { __typename?: 'Query' } & {
  catalog: { __typename?: 'Catalog' } & {
    variantsBySelections?: Types.Maybe<
      Array<
        { __typename?: 'ProductVariant' } & Pick<
          Types.ProductVariant,
          'id' | 'slug' | 'skus' | 'name'
        > & { price: { __typename?: 'Money' } & Pick<Types.Money, 'amount'> }
      >
    >;
  };
};

export const VariantsBySelectionsDocument: DocumentNode = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'VariantsBySelections' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'productSelections' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: {
                kind: 'NonNullType',
                type: {
                  kind: 'NamedType',
                  name: { kind: 'Name', value: 'ProductSelection' },
                },
              },
            },
          },
          directives: [],
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'locale' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Locale' } },
          },
          directives: [],
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'currency' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Currency' } },
          },
          directives: [],
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'slug' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Slug' } },
          },
          directives: [],
        },
      ],
      directives: [],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'catalog' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'slug' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'slug' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'locale' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'locale' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'currency' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'currency' } },
              },
            ],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'variantsBySelections' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'productSelections' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'productSelections' },
                      },
                    },
                  ],
                  directives: [],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'id' },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'slug' },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'price' },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'amount' },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'skus' },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'name' },
                        arguments: [],
                        directives: [],
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
};

/**
 * __useVariantsBySelectionsQuery__
 *
 * To run a query within a React component, call `useVariantsBySelectionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useVariantsBySelectionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVariantsBySelectionsQuery({
 *   variables: {
 *      productSelections: // value for 'productSelections'
 *      locale: // value for 'locale'
 *      currency: // value for 'currency'
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useVariantsBySelectionsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    VariantsBySelectionsQuery,
    VariantsBySelectionsQueryVariables
  >,
) {
  return ApolloReactHooks.useQuery<
    VariantsBySelectionsQuery,
    VariantsBySelectionsQueryVariables
  >(VariantsBySelectionsDocument, baseOptions);
}
export function useVariantsBySelectionsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    VariantsBySelectionsQuery,
    VariantsBySelectionsQueryVariables
  >,
) {
  return ApolloReactHooks.useLazyQuery<
    VariantsBySelectionsQuery,
    VariantsBySelectionsQueryVariables
  >(VariantsBySelectionsDocument, baseOptions);
}
export type VariantsBySelectionsQueryHookResult = ReturnType<
  typeof useVariantsBySelectionsQuery
>;
export type VariantsBySelectionsLazyQueryHookResult = ReturnType<
  typeof useVariantsBySelectionsLazyQuery
>;
export type VariantsBySelectionsQueryResult = ApolloReactCommon.QueryResult<
  VariantsBySelectionsQuery,
  VariantsBySelectionsQueryVariables
>;
