import type { CommercetoolsPackage, CfuProductItem } from '@ecomm/shop-configuration';
import type { ProductSelection } from '@ecomm/shop/models';

export type CfuData = {
  cfu: CommercetoolsPackage;
  productSelections: ProductSelection[];
};

export type CfuItem = {
  quantity: number;
  sku: string;
};

type CfuSkusForCartActions = {
  cfuItems: CfuItem[];
};

export const getCfuSkusForCartActions = ({
  cfu,
  productSelections,
}: CfuData): CfuSkusForCartActions => {
  const initialQuantity = 1;
  const accessories = [...cfu.accessories, cfu.connectedFitnessUnit] as CfuProductItem[];

  const skusForCartActions = productSelections.reduce<CfuItem[]>(
    (acc, { product, selections }) => {
      const accessory = accessories.find(({ slug }) => product === slug);

      if (selections.length === 0) {
        const sku = accessory?.variants[0].skus[0] || '';
        const existingAction = acc.find(action => action.sku === sku);
        const quantity = existingAction ? existingAction.quantity + 1 : initialQuantity;
        const actions = existingAction ? acc.filter(action => action.sku !== sku) : acc;

        return [...actions, { quantity, sku }];
      }
      const accessoryVariant = accessory?.variants.find(({ configurations }) =>
        configurations.find(({ option }) => option === selections[0].option),
      );
      return [
        ...acc,
        { quantity: initialQuantity, sku: accessoryVariant?.skus[0] || '' },
      ];
    },
    [],
  );

  return {
    cfuItems: [
      ...skusForCartActions,
      {
        quantity: initialQuantity,
        sku: (cfu.warranty as CfuProductItem).variants[0].skus[0],
      },
    ],
  };
};
