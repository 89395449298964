import { getCfuSkusForCartActions } from '@ecomm/commercetools/utils/getCfuSkusForCartActions';
import { ShopCartUpdateActionType } from '@ecomm/graphql/types.generated';

import type { VariantCommerceTools } from '@ecomm/pg-shop-accessories-display/models';
import { getSKUs } from '@ecomm/pg-shop-accessories/quickAdd/cartHelper';

import type {
  CfuCTPackage,
  BundleObject,
  CartItem,
  Upsell,
  MutationAction,
} from './types';
import { Type } from './types';

type MutationActions = MutationAction[];

export const getMutationActions = ({
  type,
  sku,
  bundleObject,
  quantity = 1,
}: CartItem): MutationActions => {
  switch (type) {
    case Type.ACCESSORY: {
      return [
        {
          addLineItem: {
            quantity: quantity,
            sku,
          },
          actionType: ShopCartUpdateActionType.AddLineItem,
        },
      ];
    }
    case Type.BUNDLE_ACCESSORY: {
      const { bundle, productSelection } = bundleObject as BundleObject;
      const { price, discountPrice, slug, discount } = bundle;
      const bundleItems = getSKUs(bundle, productSelection);

      const bundleDiscountInCents =
        discountPrice?.amount !== undefined ? price.amount - discountPrice.amount : 0;

      return [
        {
          addBundleItem: {
            bundleItems,
            sku: sku || slug,
            bundlePriceOffsetInCents: discount?.amount ?? 0,
            bundleDiscountInCents,
          },
          actionType: ShopCartUpdateActionType.AddBundleItem,
        },
      ];
    }
    case Type.CT_CFU: {
      const {
        package: cfuPackage,
        upsell: upsellOptions,
        productSelection,
      } = bundleObject as CfuCTPackage;

      const upsellActions = getUpsellMutationActions(upsellOptions ?? []);

      const { price, discount, discountPrice, slug } = cfuPackage;
      const { cfuItems } = getCfuSkusForCartActions({
        cfu: cfuPackage,
        productSelections: productSelection,
      });

      const bundleDiscountInCents =
        discountPrice?.amount !== undefined ? price.amount - discountPrice.amount : 0;

      const actions: MutationActions = [
        {
          addBundleItem: {
            bundleItems: cfuItems,
            sku: sku || slug,
            bundlePriceOffsetInCents: discount?.amount ?? 0,
            bundleDiscountInCents,
          },
          actionType: ShopCartUpdateActionType.AddBundleItem,
        },
      ];

      return upsellActions.length > 0 ? [...actions, ...upsellActions] : actions;
    }
    default: {
      return [
        {
          addLineItem: {
            quantity: 1,
            sku,
          },
          actionType: ShopCartUpdateActionType.AddLineItem,
        },
      ];
    }
  }
};

export const getUpsellMutationActions = (upsellOptions: Upsell[]) => {
  return upsellOptions.reduce<MutationAction[]>((acc, upsell) => {
    if (!upsell) return [...acc];
    let cartItem: CartItem;
    if ('bundle' in upsell) {
      const {
        bundle: { sku },
      } = upsell as BundleObject;
      cartItem = {
        sku: sku ?? '',
        type: Type.BUNDLE_ACCESSORY,
        bundleObject: upsell,
      };
    } else {
      const { sku } = upsell as VariantCommerceTools;
      cartItem = { type: Type.ACCESSORY, sku, bundleObject: undefined };
    }

    return [...acc, ...getMutationActions(cartItem)];
  }, []);
};
