import { cond, equals, always, prop } from 'ramda';
import { useTracking } from 'react-tracking';
import { useTrackWithDriftAndSegment } from '@peloton/drift/utils';
import { toDollars } from '@ecomm/models';
import type { PackageBySlugOptionalWarrantyQuery } from '@ecomm/shop/graphql/PackageBySlugOptionalWarrantyQuery.generated';
import {
  limitedEditionSkus,
  partnershipSkus,
} from '@page-builder/modules/Overview/constants';
import { toEquipmentTypeFromBundleType } from '../models';
import { BundleType } from '../models/BundleType';
import type { EquipmentBundleType } from '../models/BundleType';
import stripDashesFromId from '../models/stripDashesFromId';
import toTrackingCategory from '../models/toTrackingCategory';

const toAnalyticsId = cond<EquipmentBundleType, string>([
  [equals(BundleType.Bike), always('Bike')],
  [equals(BundleType.BikePlus), always('Bike-plus')],
  [equals(BundleType.Tread), always('Tread')],
  [equals(BundleType.TreadPlus), always('Tread')],
  [equals(BundleType.RainforestCafe), always('Guide')],
  [equals(BundleType.Row), always('Row')],
  [equals(BundleType.RefurbishedBike), always('Refurbished Bike')],
  [equals(BundleType.RefurbishedBikePlus), always('Refurbished Bike-Plus')],
]);

type TrackAddToCart = {
  packageQuery: PackageBySlugOptionalWarrantyQuery;
  cartId: string;
  bundleType: EquipmentBundleType | BundleType;
  hasAccessory?: boolean;
  hasAccessoryBundle?: boolean;
  accessoryName?: string;
  addedFromCart?: boolean;
  hasGuide?: boolean;
  hasOPC?: boolean;
  hasCPO?: boolean;
  hasTradeIn?: boolean;
};

export const useTrackAddToCart = () => {
  const { trackEvent } = useTracking();
  const { track: trackWithDriftAndSegment } = useTrackWithDriftAndSegment();

  const trackAddToCart = ({
    packageQuery,
    cartId,
    bundleType,
    hasAccessory = false,
    hasAccessoryBundle = false,
    accessoryName = '',
    addedFromCart = false,
    hasGuide = false,
    hasOPC = false,
    hasCPO = false,
    hasTradeIn = false,
  }: TrackAddToCart) => {
    const {
      connectedFitnessUnit,
      id,
      slug,
      name,
      price,
      accessories,
    } = packageQuery.catalog.packageBySlugOptionalWarranty!;
    const skus = connectedFitnessUnit.variants.flatMap(prop('skus'));
    const analyticsId = toAnalyticsId(bundleType as EquipmentBundleType);
    const properties = {
      cartId,
      id: analyticsId,
      price: toDollars(connectedFitnessUnit.price.amount),
      propertyType: 'Web',
      quantity: 1,
      graphql: true,
      sku: skus.join(','),
      hasTradeIn,
      hasAccessory,
      hasAccessoryBundle,
      accessoryName,
      addedFromCart,
      hasGuide,
      hasOPC,
      hasCPO,
    };

    trackEvent({
      event: `Added ${analyticsId}`,
      properties: {
        ...properties,
        name: analyticsId,
      },
    });

    trackWithDriftAndSegment({
      event: `Added Product`,
      properties: {
        ...properties,
        category: toTrackingCategory(toEquipmentTypeFromBundleType(bundleType)),
        slug,
        name,
        isLimitedEdition: skus.some(sku => sku && limitedEditionSkus.hasOwnProperty(sku)),
        isPartnership: skus.some(sku => sku && partnershipSkus.hasOwnProperty(sku)),
      },
    });

    if (accessories?.length) {
      trackEvent({
        event: 'Added Bundle',
        properties: {
          cartId,
          id: stripDashesFromId(id),
          name,
          graphql: true,
          price: toDollars(price.amount),
          propertyType: 'Web',
        },
      });
    }
  };

  return {
    trackAddToCart,
  };
};
