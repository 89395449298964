import React, { useEffect, useState } from 'react';
import { DomainError } from '@peloton/domain-error';
import { useErrorReporter } from '@peloton/error-reporting';
import Formatters from '@peloton/forms/formatters';
import { toLocale } from '@peloton/internationalize';
import shouldHideContent from '@ecomm/copy/helpers/shouldHideContent';
import type * as Types from '@ecomm/graphql/types.generated';
import MessageWhenNotEmpty from '@ecomm/otd/MessageWhenNotEmpty';
import type {
  CalculationStatus,
  DynamicOtdEstimate as TDynamicOtdEstimate,
} from '@ecomm/otd/models';
import { useFormattedEstimateDateRange } from '@ecomm/otd/models';
import { useToDynamicOtdEstimate } from '@ecomm/otd/useOtdEstimates';
import useCartOtdContent from './useCartOtdContent';
import type { Field as OTDField } from './useCartOtdContent';

export type OtdProps = {
  countryEstimate: string;
  cfuType: Types.EquipmentType;
  postalCode: string;
  allowDynamicPostalCodeInput?: boolean;
  additionalMarkdownRenderers?: Record<string, React.ElementType>;
  additionalValues?: Record<string, any>;
  onLoading?: (isLoading: boolean) => void;
  onEstimateSuccess?: (data: EstimateSuccessResp) => void;
  cfuSku?: string;
  otdKey?: OTDField;
};

export type EstimateSuccessResp = {
  resp: {
    minDate?: Date;
    maxDate?: Date;
    estimate?: TDynamicOtdEstimate;
    calculationStatus: CalculationStatus;
  };
  messaging: { key: string };
  postalCode: string;
};

const DynamicOtdEstimate: React.FC<React.PropsWithChildren<OtdProps>> = ({
  cfuType,
  countryEstimate,
  postalCode,
  allowDynamicPostalCodeInput,
  additionalMarkdownRenderers,
  additionalValues,
  onLoading,
  onEstimateSuccess,
  cfuSku,
  otdKey,
}) => {
  const locale = toLocale();
  const toOtdEstimate = useToDynamicOtdEstimate();
  const initialState = allowDynamicPostalCodeInput ? 'DYNAMIC_INITIAL' : 'INITIAL';
  const [calculationStatus, setCalculationStatus] = useState<
    CalculationStatus | 'INITIAL' | 'DYNAMIC_INITIAL'
  >(initialState);
  const [estimate, setEstimate] = useState<TDynamicOtdEstimate | undefined>();
  const [loading, setLoading] = useState(false);
  const { errorReporter } = useErrorReporter();

  const messaging = useCartOtdContent(otdKey || calculationStatus);
  useEffect(() => {
    (async () => {
      if (postalCode && cfuType) {
        setLoading(true);
        onLoading?.(true);
        try {
          const resp = await toOtdEstimate({
            postalCode,
            cfuType,
            cfuSku,
          });
          setCalculationStatus(resp.calculationStatus);
          setEstimate(resp.estimate);
          onEstimateSuccess?.({ resp, messaging, postalCode });
        } catch (err) {
          const error = new DomainError(`Dynamic Otd Lookup failed on cart.`, err);
          errorReporter.reportError(error, { cfuType, postalCode });
          setCalculationStatus('ERROR');
        }
        setLoading(false);
        onLoading?.(false);
      }
    })();
  }, [cfuType, postalCode]);

  const hideContent = shouldHideContent(messaging?.body);
  const estimateContent = useFormattedEstimateDateRange(
    estimate?.startDate ?? '',
    estimate?.endDate ?? '',
  );

  return messaging && !hideContent ? (
    <MessageWhenNotEmpty
      content={messaging}
      loading={loading}
      countryEstimate={countryEstimate}
      cfuType={cfuType}
      postalCode={Formatters.zipCode[locale](postalCode)}
      additionalValues={{ estimate: estimateContent, ...additionalValues }}
      additionalMarkdownRenderers={additionalMarkdownRenderers}
    />
  ) : null;
};

export default DynamicOtdEstimate;
