import { Locale } from '@peloton/internationalize/models/locale';
import { BundleType } from './models/BundleType';

export type Packages = Partial<Record<BundleType, string>>;

export const localizedBasicPackagesMap = {
  [Locale.EnglishUnitedStates]: {
    [BundleType.Bike]: 'bike-package',
    [BundleType.RefurbishedBike]: 'bike-refurbished-consumer-package',
    [BundleType.BikePlus]: 'bike-plus-basics-package-us',
    [BundleType.RefurbishedBikePlus]: 'bikeplus-refurbished-consumer-na',
    [BundleType.Tread]: 'tread-basics-package-us',
    [BundleType.RainforestCafe]: 'guide-us',
    [BundleType.Row]: 'row-basics-package-us',
    [BundleType.TreadPlus]: 'tread-plus-basics-package-us',
  },
  [Locale.EnglishAustralia]: {
    [BundleType.Bike]: 'bike-basics-package-au',
    [BundleType.BikePlus]: 'bike-plus-basics-package-au',
    [BundleType.Tread]: 'tread-basics-package-au',
    [BundleType.RainforestCafe]: 'guide-au',
  },
  [Locale.EnglishUnitedKingdom]: {
    [BundleType.Bike]: 'the-intl-bike-package',
    [BundleType.RefurbishedBike]: 'bike-refurbished-consumer-package-uk',
    [BundleType.BikePlus]: 'bike-plus-basics-package-uk',
    [BundleType.Tread]: 'tread-basics-package-uk',
    [BundleType.RainforestCafe]: 'guide-uk',
  },
  [Locale.EnglishCanada]: {
    [BundleType.Bike]: 'bike-basics-package-ca',
    [BundleType.RefurbishedBike]: 'bike-refurbished-consumer-package',
    [BundleType.BikePlus]: 'bike-plus-basics-package-ca',
    [BundleType.RefurbishedBikePlus]: 'bikeplus-refurbished-consumer-na',
    [BundleType.Tread]: 'tread-basics-package-ca',
    [BundleType.Row]: 'row-basics-package-ca',
    [BundleType.RainforestCafe]: 'guide-ca',
  },
  [Locale.GermanGermany]: {
    [BundleType.Bike]: 'the-bike-package-de',
    [BundleType.RefurbishedBike]: 'bike-refurbished-consumer-package-de',
    [BundleType.BikePlus]: 'bike-plus-basics-package-de',
    [BundleType.Tread]: 'tread-basics-package-de',
  },
};

const toBasicsPackage = (device: BundleType, locale: Locale) => {
  if (!localizedBasicPackagesMap.hasOwnProperty(locale)) {
    console.warn(`Locale "${locale}" not found in localizedBasicPackagesMap`);
    return null;
  }

  return localizedBasicPackagesMap[locale][device];
};

export default toBasicsPackage;
