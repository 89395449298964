import { useCallback } from 'react';
import { useTracking } from 'react-tracking';
import logAction from '@ecomm/logging/logAction';
import type { CtCartFragment } from '@ecomm/shop-cart/graphql/fragments.generated';
import { toCheckboxEvent } from './analytics-helper';

const useTrackGiftCheckbox = () => {
  const { trackEvent } = useTracking();

  const trackCtGiftCheckbox = useCallback(
    (
      isGift: boolean,
      position: 'cart' | 'checkout',
      cart: CtCartFragment | undefined | null,
    ) => {
      try {
        trackEvent(toCheckboxEvent('Gift Checkbox Selected', isGift, position, cart));
      } catch (e) {
        logAction('Error tracking gift checkbox select', e);
      }
    },
    [trackEvent],
  );

  return {
    trackCtGiftCheckbox,
  };
};
export default useTrackGiftCheckbox;
