// THIS IS AN AUTOGENERATED FILE (@ecomm/commercetools/graphql/codegen.yml). DO NOT EDIT THIS FILE DIRECTLY.
import * as Types from '../types.generated';

import { DocumentNode } from 'graphql';
import * as Apollo from '@apollo/client';
export type WarrantyProductPriceFragmentFragment = { __typename?: 'ProductVariant' } & {
  price?: Types.Maybe<
    { __typename?: 'ProductPrice' } & Pick<Types.ProductPrice, 'id'> & {
        value:
          | ({ __typename?: 'Money' } & Pick<Types.Money, 'currencyCode' | 'centAmount'>)
          | ({ __typename?: 'HighPrecisionMoney' } & Pick<
              Types.HighPrecisionMoney,
              'currencyCode' | 'centAmount'
            >);
      }
  >;
};

export type WarrantyProductReferencedCatalogFragmentFragment = {
  __typename?: 'ProductVariant';
} & Pick<Types.ProductVariant, 'key' | 'sku'> & {
    images: Array<{ __typename?: 'Image' } & Pick<Types.Image, 'url' | 'label'>>;
    attributesRaw: Array<
      { __typename?: 'RawProductAttribute' } & Pick<
        Types.RawProductAttribute,
        'name' | 'value'
      >
    >;
  } & WarrantyProductPriceFragmentFragment;

export type WarrantyProductReferencedFragmentFragment = { __typename?: 'Product' } & Pick<
  Types.Product,
  'id' | 'key'
> & {
    productType?: Types.Maybe<
      { __typename?: 'ProductTypeDefinition' } & Pick<Types.ProductTypeDefinition, 'key'>
    >;
    masterData: { __typename?: 'ProductCatalogData' } & {
      current?: Types.Maybe<
        { __typename?: 'ProductData' } & Pick<
          Types.ProductData,
          'name' | 'slug' | 'description'
        > & {
            masterVariant: {
              __typename?: 'ProductVariant';
            } & WarrantyProductReferencedCatalogFragmentFragment;
            variants: Array<
              {
                __typename?: 'ProductVariant';
              } & WarrantyProductReferencedCatalogFragmentFragment
            >;
          }
      >;
    };
  };

export type SearchProductsQueryVariables = Types.Exact<{
  locale?: Types.Maybe<Types.Scalars['Locale']>;
  filters?: Types.Maybe<Array<Types.SearchFilterInput>>;
  limit?: Types.Maybe<Types.Scalars['Int']>;
  offset?: Types.Maybe<Types.Scalars['Int']>;
  currency: Types.Scalars['Currency'];
  country: Types.Scalars['Country'];
}>;

export type SearchProductsQuery = { __typename?: 'Query' } & {
  productProjectionSearch: { __typename: 'ProductProjectionSearchResult' } & Pick<
    Types.ProductProjectionSearchResult,
    'total' | 'count'
  > & {
      results: Array<
        { __typename: 'ProductProjection' } & Pick<
          Types.ProductProjection,
          'id' | 'key' | 'name'
        > & {
            productType?: Types.Maybe<
              { __typename?: 'ProductTypeDefinition' } & Pick<
                Types.ProductTypeDefinition,
                'id' | 'key'
              >
            >;
            masterVariant: { __typename: 'ProductSearchVariant' } & Pick<
              Types.ProductSearchVariant,
              'id' | 'key' | 'sku'
            > & {
                attributesRaw: Array<
                  { __typename?: 'RawProductSearchAttribute' } & Pick<
                    Types.RawProductSearchAttribute,
                    'name' | 'value'
                  > & {
                      referencedResource?: Types.Maybe<
                        | { __typename?: 'Cart' }
                        | { __typename?: 'Customer' }
                        | { __typename?: 'TypeDefinition' }
                        | { __typename?: 'CustomerGroup' }
                        | { __typename?: 'Store' }
                        | ({
                            __typename?: 'Product';
                          } & WarrantyProductReferencedFragmentFragment)
                        | { __typename?: 'ProductTypeDefinition' }
                        | { __typename?: 'Category' }
                        | { __typename?: 'Channel' }
                        | { __typename?: 'ProductDiscount' }
                        | { __typename?: 'State' }
                        | { __typename?: 'TaxCategory' }
                        | { __typename?: 'CartDiscount' }
                        | { __typename?: 'ShippingMethod' }
                        | { __typename?: 'Zone' }
                        | { __typename?: 'DiscountCode' }
                        | { __typename?: 'BusinessUnit' }
                        | { __typename?: 'AssociateRole' }
                        | { __typename?: 'Order' }
                        | { __typename?: 'ShoppingList' }
                        | { __typename?: 'CustomObject' }
                        | { __typename?: 'InventoryEntry' }
                        | { __typename?: 'Message' }
                        | { __typename?: 'Review' }
                        | { __typename?: 'Extension' }
                      >;
                      referencedResourceSet: Array<
                        | { __typename?: 'Cart' }
                        | { __typename?: 'Customer' }
                        | { __typename?: 'TypeDefinition' }
                        | { __typename?: 'CustomerGroup' }
                        | { __typename?: 'Store' }
                        | ({
                            __typename?: 'Product';
                          } & WarrantyProductReferencedFragmentFragment)
                        | { __typename?: 'ProductTypeDefinition' }
                        | { __typename?: 'Category' }
                        | { __typename?: 'Channel' }
                        | { __typename?: 'ProductDiscount' }
                        | { __typename?: 'State' }
                        | { __typename?: 'TaxCategory' }
                        | { __typename?: 'CartDiscount' }
                        | { __typename?: 'ShippingMethod' }
                        | { __typename?: 'Zone' }
                        | { __typename?: 'DiscountCode' }
                        | { __typename?: 'BusinessUnit' }
                        | { __typename?: 'AssociateRole' }
                        | { __typename?: 'Order' }
                        | { __typename?: 'ShoppingList' }
                        | { __typename?: 'CustomObject' }
                        | { __typename?: 'InventoryEntry' }
                        | { __typename?: 'Message' }
                        | { __typename?: 'Review' }
                        | { __typename?: 'Extension' }
                      >;
                    }
                >;
                images: Array<
                  { __typename: 'ImageProductSearch' } & Pick<
                    Types.ImageProductSearch,
                    'label' | 'url'
                  >
                >;
                price?: Types.Maybe<
                  { __typename: 'ProductPriceSearch' } & Pick<
                    Types.ProductPriceSearch,
                    'id'
                  > & {
                      value:
                        | ({ __typename: 'Money' } & Pick<
                            Types.Money,
                            'currencyCode' | 'centAmount'
                          >)
                        | ({ __typename: 'HighPrecisionMoney' } & Pick<
                            Types.HighPrecisionMoney,
                            'currencyCode' | 'centAmount'
                          >);
                    }
                >;
              };
            variants: Array<
              { __typename: 'ProductSearchVariant' } & Pick<
                Types.ProductSearchVariant,
                'id' | 'key' | 'sku'
              > & {
                  images: Array<
                    { __typename: 'ImageProductSearch' } & Pick<
                      Types.ImageProductSearch,
                      'label' | 'url'
                    >
                  >;
                  price?: Types.Maybe<
                    { __typename: 'ProductPriceSearch' } & Pick<
                      Types.ProductPriceSearch,
                      'id'
                    > & {
                        value:
                          | ({ __typename: 'Money' } & Pick<
                              Types.Money,
                              'currencyCode' | 'centAmount'
                            >)
                          | ({ __typename: 'HighPrecisionMoney' } & Pick<
                              Types.HighPrecisionMoney,
                              'currencyCode' | 'centAmount'
                            >);
                      }
                  >;
                }
            >;
          }
      >;
    };
};

export const WarrantyProductPriceFragmentFragmentDoc: DocumentNode = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'WarrantyProductPriceFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ProductVariant' },
      },
      directives: [],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'price' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'currency' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'currency' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'country' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'country' } },
              },
            ],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'value' },
                  arguments: [],
                  directives: [],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'currencyCode' },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'centAmount' },
                        arguments: [],
                        directives: [],
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
};
export const WarrantyProductReferencedCatalogFragmentFragmentDoc: DocumentNode = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'WarrantyProductReferencedCatalogFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ProductVariant' },
      },
      directives: [],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'key' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sku' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'images' },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'url' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'label' },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'attributesRaw' },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'name' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'value' },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'WarrantyProductPriceFragment' },
            directives: [],
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'WarrantyProductPriceFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ProductVariant' },
      },
      directives: [],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'price' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'currency' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'currency' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'country' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'country' } },
              },
            ],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'value' },
                  arguments: [],
                  directives: [],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'currencyCode' },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'centAmount' },
                        arguments: [],
                        directives: [],
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
};
export const WarrantyProductReferencedFragmentFragmentDoc: DocumentNode = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'WarrantyProductReferencedFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Product' } },
      directives: [],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'id' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'key' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'productType' },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'key' },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'masterData' },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'current' },
                  arguments: [],
                  directives: [],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'name' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'locale' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'locale' },
                            },
                          },
                        ],
                        directives: [],
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'slug' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'locale' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'locale' },
                            },
                          },
                        ],
                        directives: [],
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'description' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'locale' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'locale' },
                            },
                          },
                        ],
                        directives: [],
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'masterVariant' },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'WarrantyProductReferencedCatalogFragment',
                              },
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'variants' },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'WarrantyProductReferencedCatalogFragment',
                              },
                              directives: [],
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'WarrantyProductPriceFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ProductVariant' },
      },
      directives: [],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'price' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'currency' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'currency' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'country' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'country' } },
              },
            ],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'value' },
                  arguments: [],
                  directives: [],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'currencyCode' },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'centAmount' },
                        arguments: [],
                        directives: [],
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'WarrantyProductReferencedCatalogFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ProductVariant' },
      },
      directives: [],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'key' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sku' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'images' },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'url' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'label' },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'attributesRaw' },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'name' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'value' },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'WarrantyProductPriceFragment' },
            directives: [],
          },
        ],
      },
    },
  ],
};
export const SearchProductsDocument: DocumentNode = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'SearchProducts' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'locale' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Locale' } },
          directives: [],
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'filters' } },
          type: {
            kind: 'ListType',
            type: {
              kind: 'NonNullType',
              type: {
                kind: 'NamedType',
                name: { kind: 'Name', value: 'SearchFilterInput' },
              },
            },
          },
          directives: [],
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          directives: [],
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } },
          directives: [],
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'currency' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Currency' } },
          },
          directives: [],
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'country' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Country' } },
          },
          directives: [],
        },
      ],
      directives: [],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'productProjectionSearch' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'text' },
                value: { kind: 'StringValue', value: '', block: false },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'locale' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'locale' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filters' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'filters' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'limit' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'limit' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'offset' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'offset' } },
              },
            ],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: '__typename' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'total' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'count' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'results' },
                  arguments: [],
                  directives: [],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: '__typename' },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'id' },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'key' },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'name' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'locale' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'locale' },
                            },
                          },
                        ],
                        directives: [],
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'productType' },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'key' },
                              arguments: [],
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'masterVariant' },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: '__typename' },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'key' },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'sku' },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'attributesRaw' },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'value' },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'referencedResource' },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'InlineFragment',
                                          typeCondition: {
                                            kind: 'NamedType',
                                            name: { kind: 'Name', value: 'Product' },
                                          },
                                          directives: [],
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'FragmentSpread',
                                                name: {
                                                  kind: 'Name',
                                                  value:
                                                    'WarrantyProductReferencedFragment',
                                                },
                                                directives: [],
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'referencedResourceSet',
                                    },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'InlineFragment',
                                          typeCondition: {
                                            kind: 'NamedType',
                                            name: { kind: 'Name', value: 'Product' },
                                          },
                                          directives: [],
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'FragmentSpread',
                                                name: {
                                                  kind: 'Name',
                                                  value:
                                                    'WarrantyProductReferencedFragment',
                                                },
                                                directives: [],
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'images' },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: '__typename' },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'label' },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'url' },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'price' },
                              arguments: [
                                {
                                  kind: 'Argument',
                                  name: { kind: 'Name', value: 'currency' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'currency' },
                                  },
                                },
                              ],
                              directives: [],
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: '__typename' },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'value' },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: '__typename' },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'currencyCode' },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'centAmount' },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'variants' },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: '__typename' },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'key' },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'sku' },
                              arguments: [],
                              directives: [],
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'images' },
                              arguments: [],
                              directives: [],
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: '__typename' },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'label' },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'url' },
                                    arguments: [],
                                    directives: [],
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'price' },
                              arguments: [
                                {
                                  kind: 'Argument',
                                  name: { kind: 'Name', value: 'currency' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'currency' },
                                  },
                                },
                              ],
                              directives: [],
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: '__typename' },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                    arguments: [],
                                    directives: [],
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'value' },
                                    arguments: [],
                                    directives: [],
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: '__typename' },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'currencyCode' },
                                          arguments: [],
                                          directives: [],
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'centAmount' },
                                          arguments: [],
                                          directives: [],
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'WarrantyProductPriceFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ProductVariant' },
      },
      directives: [],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'price' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'currency' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'currency' } },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'country' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'country' } },
              },
            ],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'id' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'value' },
                  arguments: [],
                  directives: [],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'currencyCode' },
                        arguments: [],
                        directives: [],
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'centAmount' },
                        arguments: [],
                        directives: [],
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'WarrantyProductReferencedCatalogFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ProductVariant' },
      },
      directives: [],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'key' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'sku' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'images' },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'url' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'label' },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'attributesRaw' },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'name' },
                  arguments: [],
                  directives: [],
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'value' },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
          {
            kind: 'FragmentSpread',
            name: { kind: 'Name', value: 'WarrantyProductPriceFragment' },
            directives: [],
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'WarrantyProductReferencedFragment' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'Product' } },
      directives: [],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'id' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'key' },
            arguments: [],
            directives: [],
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'productType' },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'key' },
                  arguments: [],
                  directives: [],
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'masterData' },
            arguments: [],
            directives: [],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'current' },
                  arguments: [],
                  directives: [],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'name' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'locale' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'locale' },
                            },
                          },
                        ],
                        directives: [],
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'slug' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'locale' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'locale' },
                            },
                          },
                        ],
                        directives: [],
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'description' },
                        arguments: [
                          {
                            kind: 'Argument',
                            name: { kind: 'Name', value: 'locale' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'locale' },
                            },
                          },
                        ],
                        directives: [],
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'masterVariant' },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'WarrantyProductReferencedCatalogFragment',
                              },
                              directives: [],
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'variants' },
                        arguments: [],
                        directives: [],
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'WarrantyProductReferencedCatalogFragment',
                              },
                              directives: [],
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
};

/**
 * __useSearchProductsQuery__
 *
 * To run a query within a React component, call `useSearchProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchProductsQuery({
 *   variables: {
 *      locale: // value for 'locale'
 *      filters: // value for 'filters'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      currency: // value for 'currency'
 *      country: // value for 'country'
 *   },
 * });
 */
export function useSearchProductsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    SearchProductsQuery,
    SearchProductsQueryVariables
  >,
) {
  return Apollo.useQuery<SearchProductsQuery, SearchProductsQueryVariables>(
    SearchProductsDocument,
    baseOptions,
  );
}
export function useSearchProductsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SearchProductsQuery,
    SearchProductsQueryVariables
  >,
) {
  return Apollo.useLazyQuery<SearchProductsQuery, SearchProductsQueryVariables>(
    SearchProductsDocument,
    baseOptions,
  );
}
export type SearchProductsQueryHookResult = ReturnType<typeof useSearchProductsQuery>;
export type SearchProductsLazyQueryHookResult = ReturnType<
  typeof useSearchProductsLazyQuery
>;
export type SearchProductsQueryResult = Apollo.QueryResult<
  SearchProductsQuery,
  SearchProductsQueryVariables
>;
