/* eslint-disable react/display-name */
import { grey, Icon } from '@pelotoncycle/design-system';
import { complement, isEmpty } from 'ramda';
import React from 'react';
import styled from 'styled-components';
import Markdown from '@peloton/copy/Markdown';
import toFormattedText from '@peloton/copy/toFormattedText';
import { media } from '@peloton/styles/breakpoints';
import type { LongHeaderBodyFootnoteData } from '@ecomm/copy';
import type * as Types from '@ecomm/graphql/types.generated';
import { Spinner } from '@ecomm/spinner';
import { b4, reg, light, b5, med } from '@ecomm/typography';
import InfoTooltip from '../cart/summary/InfoTooltip';
import { Item } from '../cart/summary/ItemView/shared';

type Props = {
  additionalValues?: { [key: string]: string };
  className?: string;
  cfuType: Types.EquipmentType;
  content: Pick<LongHeaderBodyFootnoteData, 'header' | 'body' | 'tooltip'>;
  countryEstimate: string;
  loading?: boolean;
  postalCode?: string;
  additionalMarkdownRenderers?: Record<string, React.ElementType>;
};

const MessageWhenNotEmpty: React.FC<React.PropsWithChildren<Props>> = ({
  additionalValues = {},
  className,
  cfuType,
  content: { header, body, tooltip }, // footnote = '' },
  countryEstimate,
  loading = false,
  postalCode = '',
  additionalMarkdownRenderers,
}) => {
  // It should be safe to cast to string here as the values will always be
  // strings so the output will always be a string as well.
  const formattedHeader = toFormattedText(header, {
    countryEstimate,
    cfuType,
    postalCode,
    ...additionalValues,
  }) as string;

  const formattedBody = toFormattedText(body, {
    countryEstimate,
    cfuType,
    postalCode,
    ...additionalValues,
  }) as string;

  const hasHeader = isNotEmpty(formattedHeader);
  const hasBody = isNotEmpty(formattedBody);
  const hasText = hasHeader || hasBody;
  const markdown = {
    renderers: additionalMarkdownRenderers,
  };

  return (
    <Container
      className={className}
      $loading={loading ? loading : undefined}
      hasText={hasText}
    >
      <HeaderRow hasHeader={hasHeader} $loading={loading ? loading : undefined}>
        {hasHeader ? (
          <Header>
            <Markdown content={formattedHeader} markdown={markdown} />
          </Header>
        ) : null}

        {hasHeader && tooltip && (
          <StyledInfoToolTip
            tooltipId={'deliveryDisclaimer'}
            renderTarget={({ closeTooltip, openTooltip, tooltipContentId }) => ({
              ref,
            }) => (
              <Row ref={ref}>
                <Item>
                  <button
                    type="button"
                    onClick={openTooltip}
                    onMouseOver={openTooltip}
                    onBlur={closeTooltip}
                    onFocus={openTooltip}
                    aria-describedby={tooltipContentId}
                  >
                    <StyledInfoIcon name="tooltip" primaryColor={grey[70]} height={16} />
                  </button>
                </Item>
              </Row>
            )}
          >
            <Markdown markdown={markdown} content={tooltip} />
          </StyledInfoToolTip>
        )}
        {loading && <Spinner />}
      </HeaderRow>
      {hasBody ? (
        <Message data-test-id="otdMessage">
          <Markdown content={formattedBody} markdown={markdown} />
        </Message>
      ) : null}
    </Container>
  );
};

export default MessageWhenNotEmpty;

export const StyledInfoIcon = styled(Icon)`
  cursor: pointer;
  position: relative;
  top: 3px;
  margin-bottom: 2px;
  margin-left: 1px;
  margin-right: 8px;
`;

const StyledInfoToolTip = styled(InfoTooltip)`
  ${b5}
  ${light}
  ${media.desktopLarge`
    width: 300px;
  `}
`;

const isNotEmpty = complement(isEmpty);

const Container = styled.div<{ $loading?: boolean; hasText?: boolean }>`
  min-height: ${({ $loading = false, hasText = false }) =>
    $loading && !hasText ? '20px' : 'auto'};
  margin-top: ${({ hasText, $loading }) => (hasText || $loading ? '24px' : '0')};
`;

const Row = styled.div`
  display: flex;
  align-items: center;
`;

export const HeaderRow = styled(Row)<{ $loading?: boolean; hasHeader: boolean }>`
  margin-bottom: ${({ hasHeader, $loading }) => (hasHeader || $loading ? '10px' : '0')};
`;

export const Header = styled.h2`
  ${b4}
  ${med}
  margin-right: 6px;
`;

const Message = styled.p`
  ${b4}
  ${light}

  strong {
    ${reg}
  }
  text-align: left;
`;
