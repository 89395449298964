import type { Cart, SingleItem, Item } from '@ecomm/cart/models';
import type { CommerceToolsSelectedItem } from '@ecomm/commercetools/models/commerceToolsSelectedItem';
import { toDollars } from '@ecomm/models';
import type {
  AccessoryBundle,
  BundleAccessoryType,
} from '@ecomm/pg-shop-accessories/models';

export type AddToCartItem =
  | CommerceToolsSelectedItem
  | AccessoryBundle
  | BundleAccessoryType;

export const getLegacyItemId = (selectedItem: AddToCartItem): string => {
  if ('legacyOptionId' in selectedItem) {
    return (selectedItem as CommerceToolsSelectedItem).legacyOptionId;
  }
  if ('legacyBundleId' in selectedItem) {
    return (selectedItem as BundleAccessoryType).legacyBundleId;
  }

  return (selectedItem as AccessoryBundle).id;
};

export const formatProductId = (id: string): string => {
  if (id.length !== 32) {
    return id;
  }

  return `${id.substring(0, 8)}-${id.substring(8, 12)}-${id.substring(
    12,
    16,
  )}-${id.substring(16, 20)}-${id.substring(20)}`;
};

const getProductLineItem = (selectedItem: AddToCartItem, cart: Cart): SingleItem => {
  if ('legacyOptionId' in selectedItem) {
    const legacyItemId = getLegacyItemId(selectedItem);
    return cart.items.find((item: Item) => item.id === legacyItemId) as SingleItem;
  }

  // search bundle by slug, no bundle id in cart response
  return cart.items.find((item: Item) => item.slug === selectedItem.slug) as SingleItem;
};

const createBundleEvent = (bundle: AccessoryBundle | BundleAccessoryType, cart: Cart) => {
  const { id, name, price } = bundle;
  return {
    event: 'Added Bundle',
    properties: {
      cartId: cart.id,
      graphql: true,
      id: formatProductId(id),
      name,
      price: toDollars(price.amount),
      propertyType: 'Web',
    },
  };
};

const createProductEvent = (selectedItem: CommerceToolsSelectedItem, cart: Cart) => {
  const productLineItem: SingleItem = getProductLineItem(selectedItem, cart);
  const { id, name, sku } = productLineItem;

  return {
    event: 'Added Product',
    properties: {
      sku,
      name,
      quantity: 1,
      cartId: cart.id,
      slug: selectedItem.slug,
      id: formatProductId(id),
      price: toDollars(selectedItem.price.amount),
      propertyType: 'Web',
      category: 'accessory',
      addedFromCart: false,
      giftSubscriptionDuration: null,
    },
  };
};

export const createAddToCartEvent = (selectedItem: AddToCartItem, cart: Cart) => {
  if ('legacyOptionId' in selectedItem) {
    return createProductEvent(selectedItem as CommerceToolsSelectedItem, cart);
  }

  return createBundleEvent(selectedItem as AccessoryBundle | BundleAccessoryType, cart);
};
