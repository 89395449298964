import type { LazyQueryExecFunction, ApolloError } from '@apollo/client';
import { useApolloClient } from '@apollo/client';
import { useState } from 'react';
import { useClient } from '@peloton/api/ClientContext';
import { useTrackWithDriftAndSegment } from '@peloton/drift/utils';
import { useErrorReporter } from '@peloton/error-reporting';
import { toCountryFromLocale, toCurrency, useLocale } from '@peloton/internationalize';
import {
  useHasBeenConverted,
  useOpenCartPanel,
  useSetHasBeenConverted,
  useSetIsCartLoading,
} from '@ecomm/cart-next/context/CartContext';
import { useAddPromoCodeMutation } from '@ecomm/cart-next/graphql/mutations/AddPromoCode.generated';
import { useCartQuery } from '@ecomm/cart-next/graphql/queries/Cart.generated';
import useIsReferralSession from '@ecomm/cart-next/hooks/useIsReferralSession';
import { useSetIsGiftMutation } from '@ecomm/checkout/hooks/setIsGiftOnCart.generated';
import { useCommercetoolsClient } from '@ecomm/commercetools/apollo';
import useAddCfuToCartAnalytics from '@ecomm/commercetools/hooks/useAddCfuToCartAnalytics';
import useGetCartItems from '@ecomm/commercetools/hooks/useGetCartItems';
import mapSearchProductsResponseToUpsellVariants from '@ecomm/commercetools/mappers/mapSearchProductsResponseToUpsellVariants';
import type { SearchProductsQuery } from '@ecomm/commercetools/queries/Warranties.generated';
import { useSearchProductsLazyQuery } from '@ecomm/commercetools/queries/Warranties.generated';
import type {
  Exact,
  Maybe,
  SearchFilterInput,
} from '@ecomm/commercetools/types.generated';
import useIsToggleActive from '@ecomm/feature-toggle/hooks/useIsToggleActive';
import type {
  MutationUpdateCartArgs,
  ShopCartUpdateAction,
} from '@ecomm/graphql/types.generated';
import { ShopCartUpdateActionType } from '@ecomm/graphql/types.generated';
import logAction from '@ecomm/logging/logAction';
import type { CtCartFragment } from '@ecomm/shop-cart/graphql/fragments.generated';
import { useCreateShopCartJupiterMutation } from '@ecomm/shop-cart/graphql/mutations/CreateCart.generated';
import { useUpdateShopCartJupiterMutation } from '@ecomm/shop-cart/graphql/mutations/UpdateCart.generated';
import type { GetShopCartJupiterQuery } from '@ecomm/shop-cart/graphql/queries/ShopCart.generated';
import { GetShopCartJupiterDocument } from '@ecomm/shop-cart/graphql/queries/ShopCart.generated';
import {
  getCountryCart,
  getPrepaidAAMProduct,
  getRegularAAMProduct,
  hasGiftInformation,
  isCartEligibleForGifting,
  isCartEligibleForPrepaidMembership,
  isUnavailableProductExists,
  isUnavailableProductExistsInBundles,
  WARRANTY,
  getCartIncludesHaulaway,
  isCartEligibleForHaulaway,
  getCartItemsExcludingId,
} from '../helpers/ct-cart-helper';
import { addItemToCart } from './addItemToCart';
import { convertCurrentCTCartToMonolith } from './convertCurrentCTCartToMonolith';
import type { BundleObject, CfuCTPackage, ItemType, Type } from './types';
import { useMigrationStatus } from './useMigrationStatus';

export const checkIfAllProductsAreAvailable = (cartData: any) => {
  const lineItems = cartData?.lineItems ?? [];
  return !(
    isUnavailableProductExists(lineItems) ||
    isUnavailableProductExistsInBundles(lineItems)
  );
};

export const useConvertCTCart = (setError?: Function) => {
  const { restClient, updateCartMutation } = useRestClientAndUpdateMutation();
  const apolloClient = useApolloClient();
  const [setIsGiftMutation] = useSetIsGiftMutation();
  const [addPromoCodeMutation] = useAddPromoCodeMutation();
  const isToggleActive = useIsToggleActive();
  const isProjectPhoenixEnabled = isToggleActive('projectPhoenix');
  const convertCTCart = async ({
    context,
    refetchedCTCart,
  }: {
    context: Record<string, any>;
    refetchedCTCart: GetShopCartJupiterQuery;
  }) => {
    logAction('converting shopCart items', context);
    const { error } = await convertCurrentCTCartToMonolith(
      refetchedCTCart.shopCart,
      updateCartMutation,
      setIsGiftMutation,
      addPromoCodeMutation,
      restClient,
      apolloClient,
      isProjectPhoenixEnabled,
    );
    if (error) {
      // Monolith conversion failed
      logAction('error converting shopCart items', {
        refetchedShopCart: refetchedCTCart.shopCart,
      });
      setError?.(error);
      console.error('buyFlowMigration: failed conversion', error);
    }
  };

  return {
    convertCTCart,
  };
};

export const useRestClientAndUpdateMutation = () => {
  const restClient = useClient();
  const [updateCartMutation] = useUpdateShopCartJupiterMutation();

  return {
    restClient,
    updateCartMutation,
  };
};

export const useCTCartData = () => {
  const { updateCartMutation } = useRestClientAndUpdateMutation();
  const { refetchCart, shopCartResult: shopCartData } = useGetCartItems();

  return {
    refetchCart,
    shopCartData,
    updateCartMutation,
  };
};

export const useConvertToMonolithCartForReferral = () => {
  const { refetchCart, shopCartData } = useCTCartData();
  const { convertCTCart } = useConvertCTCart();
  const { CTCartEnabled } = useMigrationStatus();
  const hasBeenConverted = useHasBeenConverted();
  const setHasBeenConverted = useSetHasBeenConverted();

  const convertToMonolithCart = async (refetchedCTCart: GetShopCartJupiterQuery) => {
    logAction('adding a referral', refetchedCTCart);
    await convertCTCart({
      refetchedCTCart,
      context: {
        shopCartData,
        refetchedShopCart: refetchedCTCart,
      },
    });
  };

  const handleCartConversion = async () => {
    if (!CTCartEnabled || hasBeenConverted) {
      return;
    }
    const {
      data: refetchedCTCart,
    }: { data: GetShopCartJupiterQuery | undefined | null } = await refetchCart();

    if (!Boolean(refetchedCTCart)) {
      logAction('refetch returns null data');
      return;
    }
    const shouldConvertToMonolith = Boolean(
      refetchedCTCart?.shopCart?.totalLineItemQuantity && !hasBeenConverted,
    );

    if (shouldConvertToMonolith) {
      // this  will be removed once we're on R3/R4
      // we have to mark it as converted before conversionn
      // so it doesn't convert twice
      setHasBeenConverted(true);
      await convertToMonolithCart(refetchedCTCart as GetShopCartJupiterQuery);
    }
  };

  return {
    handleCartConversion,
  };
};

export const useAddItemToCart = () => {
  const { refetchCart, shopCartData, updateCartMutation } = useCTCartData();
  const country = getCountryCart();
  const [createCartMutation] = useCreateShopCartJupiterMutation({
    refetchQueries: [{ query: GetShopCartJupiterDocument, variables: { country } }],
    awaitRefetchQueries: true,
  });
  const setIsCartLoading = useSetIsCartLoading();
  const openCartPanel = useOpenCartPanel();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const { convertCTCart } = useConvertCTCart(setError);
  const toggleStatus = useMigrationStatus();
  const { track } = useTrackWithDriftAndSegment();
  const trackCfuAnalytics = useAddCfuToCartAnalytics();
  const { isReferralSession } = useIsReferralSession();

  const { data: monolithCartData, refetchMonolithCart } = useMonolithCartRefetch();

  // TODO: Update type prop to better name, hard to grep for
  const draftAddItemsToCart = ({
    type,
    sku,
    bundleObject,
    cfuPackage,
    callBack,
    item,
    quantity,
  }: {
    type: Type;
    sku?: string;
    bundleObject?: BundleObject;
    cfuPackage?: CfuCTPackage;
    callBack: Function;
    item?: ItemType;
    quantity?: number;
  }) => {
    return addItemToCart({
      bundleObject,
      callBack,
      cfuPackage,
      convertCTCart,
      createCartMutation,
      monolithCartData,
      openCartPanel,
      refetchCTCart: refetchCart,
      refetchMonolithCart,
      setError,
      setIsCartLoading,
      setLoading,
      shopCartData,
      sku,
      type,
      updateCartMutation,
      toggleStatus,
      track,
      item,
      isReferralSession,
      trackCfuAnalytics,
      quantity,
    });
  };

  return { addItemToCart: draftAddItemsToCart, result: { loading, error } };
};

const createRemoveExtendedWarrantyAction = async (
  shopCart: CtCartFragment,
  searchProductsQuery: LazyQueryExecFunction<
    SearchProductsQuery,
    Exact<{
      filters?: Maybe<SearchFilterInput[]>;
    }>
  >,
  lineItemId: string,
): Promise<ShopCartUpdateAction | undefined> => {
  const extendedWarrantiesInCart = shopCart.lineItems.filter(
    lineItem => lineItem.productVariant.type === WARRANTY,
  );

  if (!extendedWarrantiesInCart.length) {
    return undefined;
  }

  const equipmentBundleInCart = shopCart.lineItems.find(
    lineItem => lineItem.id === lineItemId,
  );
  const limitedWarrantyInCart = equipmentBundleInCart?.bundleItemLineItems?.find(
    lineItem => lineItem.productVariant.type === WARRANTY,
  );

  if (limitedWarrantyInCart?.productKey) {
    try {
      const { data: queryResult } = await searchProductsQuery({
        variables: {
          filters: [
            {
              model: {
                value: {
                  path: 'key',
                  values: [limitedWarrantyInCart.productKey],
                },
              },
            },
          ],
        },
      });

      if (queryResult) {
        const productVariants = mapSearchProductsResponseToUpsellVariants(
          queryResult,
          limitedWarrantyInCart.productKey,
        );
        const extendedWarrantySkus = productVariants
          .map(variant => variant?.sku)
          .filter(Boolean);

        const matchingExtendedWarranty = extendedWarrantiesInCart.find(lineItem =>
          extendedWarrantySkus.includes(lineItem.productVariant?.sku),
        );

        if (matchingExtendedWarranty) {
          const equipmentCount = shopCart.lineItems.filter(
            lineItem =>
              lineItem.productVariant.legacyEquipmentType ===
              equipmentBundleInCart?.productVariant.legacyEquipmentType,
          ).length;

          if (matchingExtendedWarranty.quantity >= equipmentCount) {
            return {
              removeLineItem: {
                lineItemId: matchingExtendedWarranty.id,
                quantity: 1,
              },
              actionType: ShopCartUpdateActionType.RemoveLineItem,
            };
          }
        }
      }
    } catch (e) {
      logAction('Error removing extended warranties from cart');
    }
  }

  return undefined;
};

const createRemoveHaulawayAction = ({ lineItems = [] }: CtCartFragment, id: string) => {
  const { haulawayItem } = getCartIncludesHaulaway(lineItems);
  const cartWithoutLatestRemoval = getCartItemsExcludingId({
    lineItems,
    id,
  });

  if (!isCartEligibleForHaulaway(cartWithoutLatestRemoval) && haulawayItem) {
    return {
      removeLineItem: {
        lineItemId: haulawayItem.id,
        quantity: 1,
      },
      actionType: ShopCartUpdateActionType.RemoveLineItem,
    };
  }

  return null;
};

export enum RemoveType {
  SINGLE,
  BUNDLE,
}

type RemoveItemFromCart = {
  id: string;
  type: RemoveType;
  isBundleCFU: boolean;
  shopCartData: GetShopCartJupiterQuery | undefined;
  refetchCart: Function;
  updateCartMutation: Function;
  searchProductsQuery: LazyQueryExecFunction<
    SearchProductsQuery,
    Exact<{
      filters?: Maybe<SearchFilterInput[]>;
    }>
  >;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setError: React.Dispatch<React.SetStateAction<undefined | ApolloError>>;
  quantity: number;
};

type RemovePayloadType = {
  variables: MutationUpdateCartArgs;
};

type BundleRemovalPayloadType = Pick<
  RemoveItemFromCart,
  'isBundleCFU' | 'shopCartData' | 'searchProductsQuery' | 'id'
>;

const getSingleItemRemovalPayload = ({
  quantity,
  lineItemId,
}: {
  quantity: number;
  lineItemId: string;
}) => ({
  variables: {
    input: {
      country: getCountryCart(),
      actions: [
        {
          removeLineItem: {
            lineItemId,
            quantity,
          },
          actionType: ShopCartUpdateActionType.RemoveLineItem,
        },
      ],
    },
  },
});

const getBundleItemRemovalPayload = async ({
  isBundleCFU,
  shopCartData,
  searchProductsQuery,
  id,
}: BundleRemovalPayloadType) => {
  const actions = [];
  // check if CFU bundle, if yes then UI need to remove the regular AAM(All-Access-Membership) with qty:1
  if (isBundleCFU) {
    // find regular membership product
    const regularMembership = getRegularAAMProduct(
      shopCartData?.shopCart?.lineItems ?? [],
    );

    if (regularMembership) {
      actions.push({
        removeLineItem: {
          lineItemId: regularMembership?.id ?? '',
          quantity: 1,
        },
        actionType: ShopCartUpdateActionType.RemoveLineItem,
      });
    }
  }

  // Remove prepaid memberships if no bikes/treads/rows left in cart after bundle is removed
  const isCartEligible = isCartEligibleForPrepaidMembership(
    shopCartData?.shopCart?.lineItems?.filter(item => item.id !== id) ?? [],
  );
  const prepaidMembership = getPrepaidAAMProduct(shopCartData?.shopCart?.lineItems ?? []);

  if (prepaidMembership && !isCartEligible) {
    actions.push({
      removeLineItem: {
        lineItemId: prepaidMembership.id,
        quantity: 1,
      },
      actionType: ShopCartUpdateActionType.RemoveLineItem,
    });
  }

  // isGift must be false if no bikes/treads/rows/guides left in cart after bundle is removed
  const isEligibleForGifting = isCartEligibleForGifting(
    shopCartData?.shopCart?.lineItems?.filter(item => item.id !== id) ?? [],
    shopCartData?.shopCart?.locale ?? '',
  );

  if (!isEligibleForGifting && shopCartData?.shopCart?.isGift) {
    actions.push({
      setIsGift: {
        isGift: false,
      },
      actionType: ShopCartUpdateActionType.SetIsGift,
    });

    if (hasGiftInformation(shopCartData?.shopCart)) {
      actions.push({
        setGiftInformation: {
          giftMessage: '',
          gifterName: '',
          recipientEmail: '',
          recipientName: '',
        },
        actionType: ShopCartUpdateActionType.SetGiftInformation,
      });
    }
  }

  // remove bundle
  actions.push({
    removeBundleItem: {
      lineItemId: id,
    },
    actionType: ShopCartUpdateActionType.RemoveBundleItem,
  });

  // Additional removals if necessary
  if (shopCartData?.shopCart) {
    // Remove extended warranty if no eligible skus remain in cart
    const removeWarrantyAction = await createRemoveExtendedWarrantyAction(
      shopCartData?.shopCart,
      searchProductsQuery,
      id,
    );

    if (removeWarrantyAction) actions.push(removeWarrantyAction);

    // Remove haulaway item if no eligible skus remain in cart
    const removeHaulawayAction = createRemoveHaulawayAction(shopCartData.shopCart, id);

    if (removeHaulawayAction) {
      actions.push(removeHaulawayAction);
    }
  }

  return {
    variables: {
      input: {
        country: getCountryCart(),
        actions,
      },
    },
  };
};

const removeItemFromCart = async ({
  id,
  type,
  isBundleCFU,
  shopCartData,
  refetchCart,
  updateCartMutation,
  searchProductsQuery,
  setError,
  setLoading,
  quantity,
}: RemoveItemFromCart) => {
  setLoading(true);
  let removePayload = {} as RemovePayloadType;
  switch (type) {
    case RemoveType.SINGLE: {
      removePayload = getSingleItemRemovalPayload({ quantity, lineItemId: id });
      break;
    }
    case RemoveType.BUNDLE: {
      removePayload = await getBundleItemRemovalPayload({
        id,
        isBundleCFU,
        shopCartData,
        searchProductsQuery,
      });
      break;
    }
  }
  const numberOfItemsInCart = shopCartData?.shopCart?.lineItems?.length || 0;

  const numberOfItemsToRemove =
    removePayload.variables?.input?.actions.filter(action => {
      return (
        action.actionType == ShopCartUpdateActionType.RemoveBundleItem ||
        action.actionType == ShopCartUpdateActionType.RemoveLineItem
      );
    }).length || 0;

  if (numberOfItemsInCart === numberOfItemsToRemove) {
    for (const discount of shopCartData?.shopCart?.discounts ?? []) {
      //check if the coupon is a non-auto-apply coupon
      if (discount.discountCode.code.length) {
        removePayload.variables?.input?.actions.unshift({
          removeDiscount: {
            id: discount.discountCode.id,
          },
          actionType: ShopCartUpdateActionType.RemoveDiscount,
        });
      }
    }
  }

  updateCartMutation(removePayload)
    .then(() => {
      setLoading(false);
      logAction('success removing item from cart', {
        payload: removePayload,
        type,
        id,
      });
      // TODO: send result back => call onSuccess callback : Yet to decide on this
    })
    .catch((e: ApolloError) => {
      // TODO: Add TO Cart Failed
      logAction('error removing item from cart', {
        error: e,
        id,
        type,
      });
      console.error(e);
      setError(e);
      // refetching the CT cart to get the most up-to-date cart from BE to account for the multi-tab issue.
      refetchCart();
    })
    .finally(() => {
      setLoading(false);
    });
};

export const useRemoveItemFromCart = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const locale = useLocale();
  const country = toCountryFromLocale(locale);
  const currency = toCurrency(country);
  const client = useCommercetoolsClient();

  const [updateCartMutation] = useUpdateShopCartJupiterMutation({
    refetchQueries: [
      { query: GetShopCartJupiterDocument, variables: { country: getCountryCart() } },
    ],
    awaitRefetchQueries: true,
  });
  const { refetchCart, shopCartResult: shopCartData } = useGetCartItems();
  const [searchProductsQuery] = useSearchProductsLazyQuery({
    client,
    fetchPolicy: 'no-cache',
    variables: {
      locale,
      country,
      currency,
    },
  });

  const draftRemoveItemFromCart = ({
    id,
    type,
    isBundleCFU,
    quantity,
  }: {
    id: string;
    type: RemoveType;
    isBundleCFU: boolean;
    quantity?: number;
  }) => {
    removeItemFromCart({
      id,
      type,
      isBundleCFU,
      shopCartData,
      refetchCart,
      updateCartMutation,
      searchProductsQuery,
      setLoading,
      setError,
      quantity: quantity ? quantity : 1,
    });
  };

  return { removeItemFromCart: draftRemoveItemFromCart, result: { loading, error } };
};

export const useMonolithCartRefetch = () => {
  const isToggleActive = useIsToggleActive();
  const isProjectPhoenixEnabled = isToggleActive('projectPhoenix');
  const { errorReporter } = useErrorReporter();
  const { refetch, data } = useCartQuery({
    variables: { calculateEstimatedShippingPrice: isProjectPhoenixEnabled },
    suspend: false,
    notifyOnNetworkStatusChange: true,
    throwError: false,
    reportSwallowedError: errorReporter.reportError,
  });
  return { data, refetchMonolithCart: refetch };
};

export const useRecalculateCart = () => {
  const [updateCartMutation] = useUpdateShopCartJupiterMutation({});

  const recalculate = async () => {
    return updateCartMutation({
      variables: {
        input: {
          country: getCountryCart(),
          actions: [
            {
              recalculate: {
                updateProductData: true,
              },

              actionType: ShopCartUpdateActionType.Recalculate,
            },
          ],
        },
      },
    });
  };

  return {
    recalculate,
  };
};
