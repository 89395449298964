import { useMigrationStatus } from '@ecomm/pg-checkout-commercetools/utils/useMigrationStatus';
import { getStorageItem } from '../shared/getStorageItem';
import type { StorageItem } from '../shared/getStorageItem';
import { REFERRAL_CODE_STORAGE_KEY } from './usePartnerPromo';

const useIsReferralSession = () => {
  const referralInStorage = Boolean(
    (getStorageItem(REFERRAL_CODE_STORAGE_KEY) as StorageItem)?.referralCode,
  );
  const { buyFlowCheckoutEnabled } = useMigrationStatus();

  return {
    isReferralSession: buyFlowCheckoutEnabled && referralInStorage,
  };
};

export default useIsReferralSession;
