// export const debounce = <Args extends any[]>(
//   callback: (...args: Args) => void,
//   wait: number,
// ) => {
//   let timeoutId: ReturnType<typeof setTimeout> | null = null;

//   return (...args: Args) => {
//     clearTimeout(timeoutId!);
//     timeoutId = setTimeout(() => {
//       callback(...args);
//     }, wait);
//   };
// };

export const debounce = <Args extends any[]>(
  callback: (...args: Args) => void,
  wait: number,
) => {
  let timeoutId: ReturnType<typeof setTimeout> | null = null;

  const debouncedFunction = (...args: Args) => {
    if (timeoutId !== null) {
      clearTimeout(timeoutId);
    }
    timeoutId = setTimeout(() => {
      callback(...args);
    }, wait);
  };

  debouncedFunction.cancel = () => {
    if (timeoutId !== null) {
      clearTimeout(timeoutId);
      timeoutId = null;
    }
  };

  return debouncedFunction;
};
