import type { Locale } from '@peloton/internationalize';
import {
  COUNTRY_TO_COMMERCETOOLS_PREFIX,
  MAP_COUNTRY_TO_COMMERCETOOLS_PREFIX,
  toCountryFromLocale,
} from '@peloton/internationalize';
import {
  AVAILABLE,
  ProductStates,
  UNAVAILABLE,
  isAvailableForPurchase,
} from '@ecomm/product-states/models';
import type { AvailabilitySalesStatus, SalesStatuses } from '../constants';
import { AccessoryKeys, MAP_SALES_STATUS_TO_AVAILABILITY } from '../constants';
import type { ProductSearchVariant, ProductVariant } from '../types.generated';
import { getAttributeValueByName } from '../utils/getAttributeValueByName';

type SalesStatus = {
  key: string;
  label: string;
};

export const getSalesAvailabilityStatus = (
  status: string,
  country: string,
): AvailabilitySalesStatus =>
  MAP_SALES_STATUS_TO_AVAILABILITY[status.slice(country.length) as SalesStatuses];

const findSalesStatuses = (
  variant: ProductVariant | ProductSearchVariant,
  ctCountryPrefix: string,
): Array<SalesStatus> => {
  const prefixes =
    ctCountryPrefix === COUNTRY_TO_COMMERCETOOLS_PREFIX.Uk
      ? [COUNTRY_TO_COMMERCETOOLS_PREFIX.Uk, COUNTRY_TO_COMMERCETOOLS_PREFIX.Gb]
      : [ctCountryPrefix];

  const salesStatuses: SalesStatus[] = getAttributeValueByName(
    variant.attributesRaw,
    AccessoryKeys.SALES_STATUS,
  )?.filter((status: SalesStatus) => prefixes.some(p => status.key.startsWith(p)));

  return salesStatuses;
};

export const getSalesStatus = (
  variant: ProductVariant | ProductSearchVariant,
  locale: Locale,
): AvailabilitySalesStatus => {
  const ctCountryPrefix =
    MAP_COUNTRY_TO_COMMERCETOOLS_PREFIX[toCountryFromLocale(locale)];

  const salesStatuses = findSalesStatuses(variant, ctCountryPrefix);

  if (!salesStatuses || salesStatuses.length === 0) {
    return { code: AVAILABLE, state: ProductStates.Available };
  }

  return salesStatuses.map((status: SalesStatus) =>
    getSalesAvailabilityStatus(status.key, ctCountryPrefix),
  )[0];
};

export const filterVariantsBySalesStatus = (
  variants: Array<ProductVariant | ProductSearchVariant>,
  locale: Locale,
): ProductVariant[] =>
  variants.filter(
    (variant: ProductVariant | ProductSearchVariant) =>
      getSalesStatus(variant, locale).code !== UNAVAILABLE,
  ) as ProductVariant[];

export const filterVariantsByAvailability = (
  variants: Array<ProductVariant | ProductSearchVariant>,
  locale: Locale,
): ProductVariant[] =>
  variants.filter((variant: ProductVariant | ProductSearchVariant) =>
    isAvailableForPurchase(getSalesStatus(variant, locale)),
  ) as ProductVariant[];
