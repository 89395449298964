import { ApolloError } from '@apollo/client';
import type { EventType } from '@peloton/analytics/types';
import type { CartQuery } from '@ecomm/cart-next/graphql/queries/Cart.generated';
import { REFERRAL_CODE_STORAGE_KEY } from '@ecomm/cart-next/hooks/usePartnerPromo';
import { getStorageItem } from '@ecomm/cart-next/shared/getStorageItem';
import type { StorageItem } from '@ecomm/cart-next/shared/getStorageItem';
import { DiscountType, ShopCartUpdateActionType } from '@ecomm/graphql/types.generated';
import logAction from '@ecomm/logging/logAction';
import { toDollars } from '@ecomm/models';
import { callUpdateCartMutation } from '@ecomm/pg-shop-accessories/quickAdd/cartHelper';
import type { GetShopCartJupiterQuery } from '@ecomm/shop-cart/graphql/queries/ShopCart.generated';
import type { CfuCartAnalyticsModel } from '@ecomm/shop-configuration/models';
import { formatProductId } from '@ecomm/shop/graphql/addToCartUtils';
import { getCountryCart } from '../helpers/ct-cart-helper';
import { isExempt } from './exemptions';
import { getMutationActions } from './getMutationActions';
import type { BundleObject, CartItem, CfuCTPackage, ItemType } from './types';
import { Type } from './types';
import type { MigrationStatus } from './useMigrationStatus';

export type AddItemToCart = {
  type: Type;
  sku?: string;
  bundleObject?: BundleObject;
  convertCTCart: ({
    refetchedCTCart,
    context,
  }: {
    context: Record<string, any>;
    refetchedCTCart: GetShopCartJupiterQuery;
  }) => Promise<void>;
  cfuPackage?: CfuCTPackage;
  callBack: Function;
  shopCartData: GetShopCartJupiterQuery | undefined;
  updateCartMutation: Function;
  createCartMutation: Function;
  monolithCartData: CartQuery | undefined;
  openCartPanel: Function;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setError: React.Dispatch<React.SetStateAction<undefined | ApolloError>>;
  refetchCTCart: Function;
  refetchMonolithCart: Function;
  setIsCartLoading: Function;
  toggleStatus: MigrationStatus;
  track: (event: EventType<any>) => void;
  item?: ItemType;
  isReferralSession: boolean;
  trackCfuAnalytics: (props: CfuCartAnalyticsModel) => void;
  quantity?: number;
};

export const addItemToCart = async ({
  bundleObject,
  callBack,
  cfuPackage,
  convertCTCart,
  createCartMutation,
  monolithCartData,
  openCartPanel,
  refetchCTCart,
  refetchMonolithCart,
  setError,
  setIsCartLoading,
  setLoading,
  shopCartData,
  sku,
  type,
  updateCartMutation,
  toggleStatus,
  track,
  item,
  isReferralSession,
  trackCfuAnalytics,
  quantity,
}: AddItemToCart): Promise<undefined | void> => {
  const { CTCartEnabled, buyFlowATCEnabled } = toggleStatus;

  logAction('add to cart started', {
    type,
    sku,
    bundleObject,
    shopCartData,
    monolithCartData,
    buyFlowATCEnabled,
  });

  setLoading(true);
  setIsCartLoading(true);
  setError(undefined);

  let numberOfItemsInMonolithCart = monolithCartData?.cart?.numberOfItems ?? 0;

  if (numberOfItemsInMonolithCart == 0) {
    const { data: updatedMonolithCartData } = await refetchMonolithCart();
    numberOfItemsInMonolithCart = updatedMonolithCartData?.cart?.numberOfItems ?? 0;
  }

  const skuExempt = sku ? isExempt(sku) : false;

  const isCTEligible =
    numberOfItemsInMonolithCart == 0 && buyFlowATCEnabled && !skuExempt;

  const isCFUType = Type.CT_CFU === type;

  const isR3ReferralAccessory = isReferralSession && Type.ACCESSORY === type;

  const isR3ReferralCFU = isReferralSession && isCFUType && !skuExempt;

  const shouldUseLegacyATC = !CTCartEnabled || !!numberOfItemsInMonolithCart;

  const legacyATC = async (context?: Record<string, any>) => {
    logAction('adding to monolith cart', context);
    await callBack(); // monolith ATC
    logAction('added to monolith cart successfully');
    setLoading(false);
    setIsCartLoading(false);
  };

  if (shouldUseLegacyATC) {
    await legacyATC();
    return;
  }

  // TODO: is this accurate? Do we want to be more explicit and check numberOfItemsInMonolithCart?
  logAction('no items in monolith cart');

  let updatedShopCartData: GetShopCartJupiterQuery | undefined = undefined;

  try {
    const { data } = await refetchCTCart();
    if (!data) {
      logAction('refetch returns null data');
      setError(new ApolloError({ errorMessage: 'Oops something went wrong!' }));
      setLoading(false);
      setIsCartLoading(false);
      return;
    }

    updatedShopCartData = data;
  } catch (e) {
    setError(e);
    logAction('refetch threw an error and failed', e);
    setLoading(false);
    setIsCartLoading(false);
    console.error(e);
    return;
  }

  const updateShopCart = async (cartItem: CartItem) => {
    const actions = getMutationActions(cartItem);
    const country = getCountryCart();

    const referralInStorage = getStorageItem(REFERRAL_CODE_STORAGE_KEY) as StorageItem;
    let referralCode;
    if (referralInStorage) {
      referralCode = referralInStorage.referralCode;
    }
    if (referralCode) {
      actions.push({
        addDiscount: {
          code: referralCode,
          type: DiscountType.ReferralDiscount,
        },
        actionType: ShopCartUpdateActionType.AddDiscount,
      });
    }

    logAction('calling update shopCart', { actions });
    try {
      await callUpdateCartMutation(
        actions,
        country,
        updatedShopCartData?.shopCart,
        updateCartMutation,
        createCartMutation,
      );

      if (cartItem.type === Type.ACCESSORY || cartItem.type === Type.BUNDLE_ACCESSORY) {
        track({
          event: 'Added Product',
          properties: {
            sku,
            quantity: quantity ?? 1,
            cartId: updatedShopCartData?.shopCart?.id,
            propertyType: 'Web',
            addedFromCart: false,
            giftSubscriptionDuration: null,
            category: 'accessory',
            slug: item?.slug,
            price: toDollars(item?.price?.amount ?? 0),
            name: item?.name,
            id: formatProductId(item?.id ?? ''),
          },
        });
      } else {
        if (cartItem.type === Type.SPARE_PART) {
          return;
        }
        trackCfuAnalytics({
          ...(cartItem.bundleObject as CfuCTPackage).analyticsProperties,
          cartId: updatedShopCartData?.shopCart?.id ?? '',
        });
      }

      if (referralCode) {
        logAction('CT Add To Cart: applied referral discount', {
          cartID: updatedShopCartData?.shopCart?.id,
        });
      }

      logAction('updated cart successfully');
      openCartPanel();
      // TODO: send result back => call onSuccess callback : Yet to decide on this
    } catch (e) {
      logAction('failed to update cart', {
        cartID: updatedShopCartData?.shopCart?.id,
        error: e,
      });
      console.error(e);
      setError(e);
    } finally {
      setIsCartLoading(false);
      setLoading(false);
    }
  };

  if (isCTEligible || isR3ReferralCFU || isR3ReferralAccessory) {
    if (isCFUType && !cfuPackage?.isSelectionValid) {
      logAction('Invalid cfu selection', { cfuPackage });
      return Promise.reject(new Error('Required selections were not provided'));
    }

    if (!sku) {
      logAction('no bundle object or sku provided for ATC', { type, bundleObject, sku });
      return;
    }

    await updateShopCart({
      type,
      sku,
      bundleObject: Boolean(cfuPackage) ? cfuPackage : bundleObject,
      quantity,
    });
    return;
  }

  // buy flow is not active

  logAction('buy flow experiment is not active', { buyFlowATCEnabled });

  // refetch the latest cart to account for the multi-tab issue
  const numberOfItemsInCTCart = updatedShopCartData?.shopCart?.totalLineItemQuantity ?? 0;
  if (Boolean(numberOfItemsInCTCart)) {
    await convertCTCart({
      refetchedCTCart: updatedShopCartData as GetShopCartJupiterQuery,
      context: {
        updatedShopCartData,
        numberOfItemsInCTCart,
      },
    });
  }

  await legacyATC({
    type,
    bundleObject,
    sku,
  });
};
