import { asyncComponent } from '@peloton/code-splitting';
import AccessibleTooltip from './AccessibleTooltip';
import type { OwnProps as TProps } from './types';
import { Placement } from './types';

const Tooltip = asyncComponent(
  () => import('./Tooltip' /* webpackChunkName: "Tooltip" */),
);
export default Tooltip;

export { Placement, AccessibleTooltip };

export type Props = TProps;
