import type { CfuCartAnalyticsModel } from '@ecomm/shop-configuration/models';
import { useTrackAddToCart } from '@ecomm/shop/graphql/useTrackAddToCart';

const useAddCfuToCartAnalytics = () => {
  const { trackAddToCart } = useTrackAddToCart();

  return ({
    bundleType,
    bundlePackage,
    cartId,
    hasAccessory,
    hasAccessoryBundle,
    accessoryName,
    addedFromCart,
    hasGuide,
    hasCPO,
    hasOPC,
  }: CfuCartAnalyticsModel) => {
    const { __typename, ...bundleData } = bundlePackage;

    trackAddToCart({
      packageQuery: {
        catalog: {
          packageBySlugOptionalWarranty: {
            ...bundleData,
          },
        },
      },
      cartId,
      bundleType,
      hasAccessory,
      hasAccessoryBundle,
      accessoryName,
      addedFromCart,
      hasGuide,
      hasOPC,
      hasCPO,
    });
  };
};

export default useAddCfuToCartAnalytics;
